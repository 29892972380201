export const STATUS_INITIATED = 'initiated' // The call is initiated
export const STATUS_RINGING = 'ringing' // The call is currently ringing (relative to us)
export const STATUS_QUEUED = 'queued' // The call is ready and waiting in line before going out. (from us to outside world)
export const STATUS_INTRANSFER = 'in-transfer' // the call is in transfer from one agent to another
export const STATUS_INPROGRESS = 'in-progress' // The call was answered and is currently in progress.
export const STATUS_COMPLETED = 'completed' // The call was answered and has ended normally.
export const STATUS_BUSY = 'busy' // The caller received a busy signal.
export const STATUS_FAILED = 'failed' // The call could not be completed as dialed, most likely because the phone number was non-existent.
export const STATUS_NOANSWER = 'no-answer' // The call ended without being answered.
export const STATUS_ANSWERED = 'answered' // The call is answered
export const STATUS_CANCELED = 'canceled' // The call was canceled via the REST API while queued or ringing.
export const STATUS_TIMEOUT = 'timeout' // The call rang enough but nobody answered it.
export const STATUS_VOICEMAIL = 'voicemail' // The call hit our voicemail
export const STATUS_MISSED = 'missed' // The call was missed
export const STATUS_ABANDONED = 'abandoned' // The call was abandoned
export const STATUS_RECORDED = 'recorded' // The call was recorded
export const STATUS_UNANSWERED = 'unanswered' // The call was not answered
export const STATUS_LIVE = 'live' // The call is live
export const STATUS_HOLD = 'hold' // The call is on hold
export const STATUS_DEADEND = 'deadend' // The call is deadend
export const ANY_COMMUNICATION_ANSWER_STATUS = 'all'
export const STATUS_SMS_RECEIVED = 'received' // A SMS is received by our system.
export const STATUS_SMS_SENT = 'sent' // A SMS is sent to the system.
export const STATUS_SMS_DELIVERED = 'delivered' // A SMS is delivered.
export const STATUS_SMS_ACCEPTED = 'accepted' // A SMS is accepted.
export const STATUS_SMS_QUEUED = 'queued' // A SMS is queued.
export const STATUS_SMS_SENDING = 'sending' // A SMS is sending.
export const STATUS_SMS_RECEIVING = 'receiving' // A SMS is receiving.
export const STATUS_SMS_UNDELIVERED = 'undelivered' // A SMS is undelivered.
export const STATUS_SMS_FAILED = 'failed' // A SMS is failed.
export const STATUS_SMS_PROCESSING = 'processing' // A FAX is processing.
export const STATUS_SMS_NO_ANSWER = 'no-answer' // A FAX is no-answer.
export const STATUS_SMS_BUSY = 'busy' // A FAX is busy.
export const STATUS_SMS_CANCELED = 'canceled' // A FAX is canceled.
export const STATUS_INITIATED_NEW = 1 // The call is initiated
export const STATUS_RINGING_NEW = 2 // The call is currently ringing (relative to us)
export const STATUS_QUEUED_NEW = 3 // The call is ready and waiting in line before going out. (from us to outside world)
export const STATUS_INTRANSFER_NEW = 4 // the call is in transfer from one agent to another
export const STATUS_INPROGRESS_NEW = 5 // The call was answered and is currently in progress.
export const STATUS_COMPLETED_NEW = 6 // The call was answered and has ended normally.
export const STATUS_BUSY_NEW = 7 // The caller received a busy signal.
export const STATUS_FAILED_NEW = 8 // The call could not be completed as dialed, most likely because the phone number was non-existent.
export const STATUS_NOANSWER_NEW = 9 // The call ended without being answered.
export const STATUS_ANSWERED_NEW = 10 // The call is answered
export const STATUS_CANCELED_NEW = 11 // The call was canceled via the REST API while queued or ringing.
export const STATUS_TIMEOUT_NEW = 12 // The call rang enough but nobody answered it.
export const STATUS_SMS_RECEIVED_NEW = 13 // A SMS is received by our system.
export const STATUS_SMS_SENT_NEW = 14 // A SMS is sent to the system.
export const STATUS_SMS_DELIVERED_NEW = 15 // A SMS is delivered.
export const STATUS_SMS_ACCEPTED_NEW = 16 // A SMS is accepted.
export const STATUS_SMS_QUEUED_NEW = 17 // A SMS is queued.
export const STATUS_SMS_SENDING_NEW = 18 // A SMS is sending.
export const STATUS_SMS_RECEIVING_NEW = 19 // A SMS is receiving.
export const STATUS_SMS_UNDELIVERED_NEW = 20 // A SMS is undelivered.
export const STATUS_SMS_FAILED_NEW = 21 // A SMS is failed.
