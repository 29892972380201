export default {
  data () {
    return {
      inboxTaskRoutes: [
        'Communications',
        'Communications View',
        'Communications Channel Task Status',
        'Communications Contact Task',
        'Communications View Contact Task'
      ],
      inboxTaskAndCommRoutes: [
        'Communications Contact Task',
        'Communications Channel Task Status',
        'Communications Contact',
        'Communications Contact Communication',
        'Communications Channel',
        'Communications View',
        'Communications View Contact Task'
      ]
    }
  }
}
